import React from "react";
import styles from "./index.module.less";
import ChinaIcon from "@images/china.svg";
import ShanxiIcon from "@images/shanxiwenhua.png";

export default () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerBlock}>
        <div className={styles.footerText}>
          <div className={styles.footerRecord}>
            <span
              onClick={() => {
                window.open("https://beian.miit.gov.cn/");
              }}
              className={styles.footerLink}
            >
              陕ICP备2023011298号-1 ｜
            </span>
            <a
              href="http://ccm.mct.gov.cn/ccnt/sczr/service/business/emark/toDetail/e9df61bcbdf94cdb8ecb65b2957393eb"
              className={styles.footerImgLink}
              target="_blank"
            >
              <img src={ShanxiIcon} alt="" className={styles.shanxiIcon} />
            </a>
            <a
              href="/certificate/1.jpg"
              className={styles.footerLink}
              target="_blank"
            >
              {" "}
              陕网文[2023] 1364-036号 ｜{" "}
            </a>
            <a
              href="/certificate/2.jpg"
              target="_blank"
              className={styles.footerLink}
            >
              {" "}
              增值电信业务经营许可证：陕B2-20180012 ｜{" "}
            </a>
            <a
              href="/certificate/3.jpg"
              target="_blank"
              className={styles.footerLink}
            >
              {" "}
              信息网络传播视听节目许可证号：2708321
            </a>
          </div>
          <div className={styles.footerCentre}>
            <span>
              违反不良信息举报电话：
              <a href="tel:029-89554354" className={styles.footerTel}>
                029-89554354 ｜{" "}
              </a>
            </span>
            <a
              href="/certificate/4.jpg"
              target="_blank"
              className={styles.footerLink}
            >
              广播电视节目制作经营许可证：（陕）字第00118号 ｜{" "}
            </a>
            <span
              onClick={() => {
                window.open(
                  "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61011302000264"
                );
              }}
              className={styles.footerLink}
            >
              陕公网安备 61011302000264号
            </span>
            <img src={ChinaIcon} alt="" className={styles.footerIcon} />
          </div>
          <div className={styles.footerChat}>
            <span
              onClick={() => {
                window.open("http://www.wmcn.com");
              }}
              className={styles.footerLink}
            >
              中国西部文化网（www.wmcn.com）Copyright © 2003-
              {new Date().getFullYear()} 西安睿辰文化传播有限责任公司版权所有
            </span>
          </div>
          <div className={styles.footerChat}>
            <span>
              <span className={styles.footerPhone}></span>
              联系我们：
              <a href="tel:029-89554354" className={styles.footerTel}>
                029-89554354
              </a>
            </span>
          </div>
        </div>
        <div className={styles.footerLine}></div>
      </div>
    </div>
  );
};
