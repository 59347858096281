import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Popover } from "antd";
import styles from "./index.module.less";
import WestLogo from "@images/logo.png";

const Tabbar: React.SFC<any> = (props) => {
  const { tabName } = props;
  const history = useHistory();
  const historyName = history.location.pathname;
  return (
    <div className={styles.tabbar}>
      <div className={styles.tabbarContainer}>
        <div className={styles.tabbarLogo}>
          <img
            src={WestLogo}
            alt=""
            onClick={() => history.push("/home")}
            className={styles.logoImg}
          />
        </div>
        <ul className={styles.tabbarNav}>
          {tabName.map((item) => {
            return (
              <li key={item.href} className={styles.tabbarNavli}>
                <NavLink
                  to={item.href}
                  isActive={() => {
                    if (historyName && historyName.includes(item.href)) {
                      return true;
                    }
                    return false;
                  }}
                  className={styles.tabbarNavlink}
                >
                  {item.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div>
          <Popover
            placement="bottom"
            content={
              <img src="footerImg/download.png" style={{ width: "150px" }} alt="" />
            }
          >
            <div className={styles.downApp}>下载APP</div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default Tabbar;
