import React from "react"
import ReactDOM from "react-dom"

// import "babel-polyfill"
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import App from "./app/App"
import routes from "./routes/index"
import Layout from "./layout"

const renderApp = props => {
	ReactDOM.render(
		<Layout>
			<App {...props} routes={routes} />
		</Layout>,
		document.getElementById("root")
	)
}

renderApp({})
