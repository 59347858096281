import React from "react";
import { BackTop, Icon } from "antd";
import styles from "./index.module.less";

import { HashRouter as Router } from "react-router-dom";

import Tabbar from "./tabbar";
import Footer from "./footer";

const Layout: React.SFC = (props) => {
  const tabName = [
    { href: "/home", name: "首页" },
    { href: "/news", name: "资讯" },
    { href: "/live", name: "艺播在线" },
    { href: "/article", name: "原创" },
    // { href: "/exhibition", name: "展览" },
    // { href: "/video", name: "播放器样式" },
  ];
  return (
    <Router>
      <div className={styles.appStyle}>
        <Tabbar tabName={tabName} />
        <div style={{ paddingTop: 80 }}>{props.children}</div>
        <div className={styles.scrollTop}>
          <BackTop visibilityHeight={400} className={styles.scrollTopBack}>
            <div className={styles.scrollTopInner}>
              <Icon
                type="arrow-up"
                style={{ color: "#FFFFFF", fontSize: "22px" }}
              />
            </div>
          </BackTop>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default Layout;
