import * as React from 'react'
import { Skeleton } from 'antd'

const lazy = React.lazy
const Suspense = React.Suspense

export default routes => routes.map(({ loader, path, checkUrl }) => {
  const Component = lazy(loader)
  return ({
    path,
    checkUrl,
    component: (props) => (
      <Suspense fallback={<div style={{ width: 1200, margin: '20px auto' }}><Skeleton active/></div>}>
        <Component {...props} />
      </Suspense>
    ),
  })
})
