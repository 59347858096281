import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";
import getRoutes from "./lazy-load";
import routes from "../routes";

import "@src/styles/antd/antdGlobal.less";
import "@src/styles/antd/antd.css";
import '@src/styles/index.less'

const App: React.SFC<any> = () => (
  <div className="admin-m1">
    <ConfigProvider locale={zhCN}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          {getRoutes(routes).map((r: any) => (
            <Route
              exact
              key={r.path}
              path={r.path}
              render={(props) => {
                return <r.component {...props} route={r} />;
              }}
            />
          ))}
        </Switch>
      </Router>
    </ConfigProvider>
  </div>
);

export default App;
