export default [
  {
    path: "/home",
    loader: () => import("@pages/home"),
  },
  // 资讯
  {
    path: "/news",
    checkUrl: "/news",
    loader: () => import("@pages/news"),
  },
  {
    path: "/news-detail",
    checkUrl: "/news-detail",
    loader: () => import("@pages/news/detail"),
  },
  // 直播
  {
    path: "/live",
    checkUrl: "/live",
    loader: () => import("@pages/live"),
  },
  {
    path: "/live-detail",
    checkUrl: "/live-detail",
    loader: () => import("@pages/live/detail"),
  },
  // 原创
  {
    path: "/article",
    checkUrl: "/article",
    loader: () => import("@pages/article"),
  },
  {
    path: "/article-detail",
    checkUrl: "/article-detail",
    loader: () => import("@pages/article/detail"),
  },
  // 展播
  {
    path: "/hundredShow",
    checkUrl: "/hundredShow",
    loader: () => import("@pages/hundredShow"),
  },
  {
    path: "/hundredShow-detail",
    checkUrl: "/hundredShow-detail",
    loader: () => import("@pages/hundredShow/detail"),
  },
  // 展览
  {
    path: "/exhibition",
    checkUrl: "/exhibition",
    loader: () => import("@pages/exhibition"),
  },
];
